var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-0 py-0" },
    _vm._l(_vm.convertBoldTag(_vm.splitMessageLine(_vm.value)), function(
      messages,
      index
    ) {
      return _c(
        "p",
        { key: index, staticClass: "ma-0 pa-0", attrs: { align: "left" } },
        _vm._l(messages, function(message, index) {
          return _c(
            "span",
            {
              key: index,
              class: message.class,
              staticStyle: { display: "inline-block" },
              attrs: { align: "left" }
            },
            [_vm._v("\n      " + _vm._s(message.text) + "\n    ")]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }