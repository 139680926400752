<template>
  <div class="px-0 py-0">
    <p
      v-for="(messages, index) in convertBoldTag(splitMessageLine(value))"
      class="ma-0 pa-0"
      :key="index"
      align="left"
    >
      <span
        v-for="(message, index) in messages"
        :key="index"
        style="display: inline-block;"
        :class="message.class"
        align="left"
      >
        {{ message.text }}
      </span>
    </p>
  </div>
</template>

<script>
import { ConvertionTag } from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'MultiLineBoldText',
  props: {
    value: String,
  },
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },

    // 太字タグを変換する
    convertBoldTag(messages) {
      if (!messages) return messages;

      // タグで区切った文字列を配列に収める
      return messages.map(message => {
        const sTag = ConvertionTag.Bold.Start;
        const eTag = ConvertionTag.Bold.End;
        const regex = new RegExp(
          `${sTag}((?:(?!${sTag})[^\s　])+)${eTag}`,
          'g'
        ); // タグ(二文字以上)で囲まれた範囲
        // 対象値（タグ付き）
        const targetValues = message.match(regex);

        // 対象値が存在する場合
        if (targetValues) {
          // タグで文字列を区切る
          const values = message.split(regex);
          // 対象値
          const targets = targetValues.map(
            v =>
              v
                .replace(new RegExp(sTag, 'g'), '') // 開始タグを除去する
                .replace(new RegExp(eTag, 'g'), '') // 終了タグを除去する
          );

          return values.map(value => ({
            text: value,
            // 対象値に一致する場合、太字指定とする
            class: targets.includes(value) ? 'font-weight-black' : '',
          }));
        } else {
          return [
            {
              text: message,
              class: '',
            },
          ];
        }
      });
    },
  },
};
</script>
