<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card class="pa-4">
      <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>

      <v-card-text
        v-if="text !== null"
        class="font-weight-black text-center pl-0 pr-0 mx-auto"
      >
        {{ text }}
      </v-card-text>
      <v-card-text
        v-for="(text, index) in splitMessageLine(multiLineText)"
        :key="index"
        :class="isLineBreakStyle(text)"
        align="left"
      >
        {{ text }}
      </v-card-text>
      <v-card-text>
        <MultiLineBoldText :value="multiLineBoldText" />
      </v-card-text>
      <v-card-text
        v-if="subText !== null"
        class="font-weight-black text-center"
        >{{ subText }}</v-card-text
      >

      <v-card-actions class="justify-center">
        <template v-if="negativeButtonTitle !== null">
          <v-btn
            rounded
            class="title font-weight-black mr-4"
            color="back"
            width="30%"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
            >{{ negativeButtonTitle }}</v-btn
          >
        </template>
        <v-btn
          :disabled="disabled"
          rounded
          class="title font-weight-black"
          color="next"
          width="30%"
          max-width="170px"
          style="font-size:15px !important"
          dark
          @click="onClickPositiveButton"
          >{{ positiveButtonTitle }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
import MultiLineBoldText from '@/components/organisms/common/MultiLineBoldText';

export default {
  name: 'Dialog',
  components: { MultiLineBoldText },
  props: {
    maxWidth: Number,
    title: String,
    text: String,
    subText: String,
    multiLineText: String || null,
    multiLineBoldText: String || null,
    negativeButtonTitle: String,
    positiveButtonTitle: String,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      breakLineStyle: ['ma-0', 'px-2', 'py-3'],
      noBreakLineStyle: ['ma-0', 'px-2', 'py-0'],
    };
  },
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    isLineBreakStyle(message) {
      return message ? this.noBreakLineStyle : this.breakLineStyle;
    },
  },
};
</script>
